<template>
    <v-container>
        <section>
            <h2>About Us</h2>
            <p class="mt-3">
                Welcome to our platform, where AI-driven insights meet market intelligence. 
                We specialize in generating high-quality market reports tailored to user requests 
                by leveraging the power of AI, Large Language Models (LLMs), and agent technologies.
            </p>
            
            <h3 class="mt-5">Meet the Founders</h3>
            <p class="mt-3">
                <strong>Dennis Sheu</strong> and <strong>Dennis Kung</strong> (D2) are seasoned 
                computer science engineers with over 30 years of experience in the software 
                and IoT industries. With expertise spanning across Silicon Valley and Asia, 
                they have built this platform to redefine market analysis by integrating 
                cutting-edge AI technologies.
            </p>
            
            <p class="mt-3">
                Our mission is to make market research faster, more accessible, and highly 
                customizable, empowering businesses and individuals with data-driven insights.
            </p>
        </section>
    </v-container>
</template>